// Hungarian
export default{
    form: {
        startSurveyButton: "Kezdjük el",
        nextButton: "Következő",
        submitAnswerButton: "Válasz beküldése",
        continueButton: "Folytatás",
        finishButton: "Befejezés",
        headerPercentageCompletion: 'kész',
        headerQuestionsCompleted: 'kérdések',
        headerDefaultErrorMessage: 'Hoppá! Valami hiba történt.',
        brandingPoweredBy: 'Powered by',
        brandingPromoWhySurvey: 'Miért végeznél felmérést, ha használhatod a <b>{product}</b>-t?',
        brandingPromoGetStarted: 'Kezdd el még ma!',
        submissionPending: "A voiceform beküldése folyamatban van. Kérjük, ne zárja be a böngésző ablakát.",
        submissionCanCloseWindow: "A voiceform sikeresen be lett küldve. Most bezárhatja az ablakot 👍",
        mediaAttachmentOverlayCloseButton: "Bezárás",
        expiredFormHeader: "Köszönjük, hogy benéztél!",
        expiredFormMessage: "Úgy tűnik, ez a felmérés lejárt. Kérjük, értesítsd a voiceform készítőjét.",
        notPublishedFormHeader: "A voiceform nincs közzétéve!",
        notPublishedFormMessage: "Úgy tűnik, ez a felmérés nincs közzétéve. Kérjük, értesítsd a voiceform készítőjét.",
        notPublishedFormRetryButton: "Próbáld újra",
        errorFormHeader: "Hoppá!",
        errorFormMessage: "Valami hiba történhetett. Kérjük, próbáld meg később.",
        errorFormRetryButton: "Próbáld újra",
        emptyFormHeader: "Köszönjük, hogy benéztél!",
        emptyFormMessage: "Hoppá! Úgy tűnik, ez az űrlap jelenleg üres, nincsenek kérdések vagy kitöltendő mezők. Elnézést kérünk a kellemetlenségekért. Kérjük, értesítsd az űrlap készítőjét a problémáról. Köszönjük megértésed és együttműködésed!",

        restoreFormHeader: "Úgy tűnik, van egy folyamatban lévő űrlapod!",
        restoreFormMessage: "Szeretnéd folytatni onnan, ahol abbahagytad?",
        restoreFormButton: "Űrlap folytatása",
        restoreFormButtonNew: "Kezdd el újra",

        audioPermissionHeader: "Szeretnél hanggal válaszolni?",
        audioPermissionMessage: "A beszéd gyorsabbá és egyszerűbbé teszi a válaszadást.",
        audioPermissionAcceptButton: "Igen, szeretném",
        audioPermissionDenyButton: "Nem, köszönöm",
        audioPermissionErrorHeader: "A mikrofon nem elérhető",
        audioPermissionErrorMessage: "Kérjük, ellenőrizze a böngésző jogosultságait, hogy engedélyezze a mikrofon elérését.",
        audioPermissionErrorButton: "OK",
        audioRecorderFailedUpload: "A felvétel feltöltése nem sikerült",
        silenceDetectorError: "Nem hallunk téged. Kérjük, ellenőrizd a mikrofon beállításait. Tipp: Lehet, hogy a külső mikrofon az oka.",
        audioRecorderButtonRecord: "Felvétel indítása",
        audioRecorderButtonRecordMore: "További felvétel",
        voiceResponseSelectionMessage: "Válassz, hogyan szeretnél válaszolni...",
        voiceResponseSelectionOrDivider: "vagy",
        voiceResponseTextInputPlaceholder: "Írd be a választ",
        voiceResponseTextInputCharacterCounterMinMessage: "Kérjük, adj meg legalább {min} karaktert",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} karakter (minimum {min} karakter)",
        voiceResponseTextInputCharacterValidationMessage: "{count} karakter (minimum {min} karakter)",

        // checkbox
        checkboxValidationTooFew: "Legalább {min} lehetőséget kell választanod",
        checkboxValidationTooMany: "Legfeljebb {max} lehetőséget választhatsz",
        checkboxNoneOfTheAboveOption: "Egyik sem",
        checkboxInvalidAnswer: "Érvénytelen válasz.",

        // datepicker
        datePickerPlaceholder: "Válassz dátumot",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "Érvénytelen e-mail.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Videó felvétele",
        fileUploadPluginScreenCast: "Képernyő felvétel",
        fileUploadVideoImportFilesDefault: 'Rögzíts vagy tölts fel videót a következő módon:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Válassz egy videórögzítési lehetőséget:',
        fileUploadVideoImportFilesNoCamera: 'Nyomd meg a gombot a képernyő videó rögzítéséhez',
        fileUploadVideoImportFilesNoScreenCast: 'Nyomd meg a gombot a videó rögzítéséhez',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Nincs elérhető lehetőség videó rögzítésére',

        // matrix
        matrixValidationMessage: "A válasz érvényes.",
        matrixRow: "Sor",
        matrixColumn: "Oszlop",
        matrixRowRequired: "A(z) {rowTitle} sor kötelező.",
        matrixRadioGroup: "Rádiócsoport",
        matrixCheckboxGroup: "Checkbox csoport",
        matrixGroupRequired: "A(z) {rowTitle} sorhoz. {type} {groupTitle} kötelező.",
        matrixColumnRequired: "A(z) {rowTitle} sorhoz. Az oszlop {columnTitle} kötelező.",
        matrixColumnInvalid: "A(z) {rowTitle} sorhoz. Az oszlop {columnTitle} érvénytelen.",
        matrixRequired: "Kötelező.",
        matrixNumericMustBeNumber: "Számnak kell lennie.",
        matrixNumericMustBeGreaterThenMin: "Nagyobbnak kell lennie, mint {min}.",
        matrixNumericMustBeLessThenMax: "Kisebbnek kell lennie, mint {max}.",
        matrixNumericMustBeInteger: "Egész számnak kell lennie.",
        matrixNumericInvalidNumber: "Érvénytelen szám.",

        // name
        nameLabel: "Név",
        nameInvalid: "Adja meg a kereszt- és vezetéknevet.",

        // nps
        npsNotLikely: "Egyáltalán nem valószínű",
        npsExtremelyLikely: "Nagyon valószínű",

        // numeric input
        numericInputRequired: "Kötelező.",
        numericInputMustBeNumber: "Számnak kell lennie.",
        numericInputMustBeGreaterThenMin: "Nagyobbnak kell lennie, mint {min}.",
        numericInputMustBeLessThenMax: "Kisebbnek kell lennie, mint {max}.",
        numericInputMustBeInteger: "Egész számnak kell lennie.",
        numericInputInvalidNumber: "Érvénytelen szám.",
        numericInputPlaceholder: "Írj be egy számot",

        // phone
        phoneInvalid: "A telefonszám érvénytelen.",
        phoneCountrySelectorLabel: 'Országkód',
        phoneCountrySelectorError: 'Válassz országot',
        phoneNumberLabel: 'Telefonszám',
        phoneExample: 'Példa:',

        // boolean
        booleanYesLabel: "Igen",
        booleanNoLabel: "Nem",

        //questionStep
        questionStepAudioQuestionLabel: "Hangos kérdés",

        // errors
        invalidPhoneNumber: "{phone} érvénytelen telefonszám.",
        invalidEmail: "{email} érvénytelen e-mail cím.",
        questionIsRequired: "A kérdés kötelező.",
        answerIsNotValid: "A válasz érvénytelen.",
        unfinishedProbingDialogError: "Kérjük, fejezd be a párbeszédet.",
        cannotResumePartialResponse: "Nem lehet folytatni a részleges választ.",
        failedToSubmitForm: "Nem sikerült beküldeni a űrlapot. Ellenőrizze az internetkapcsolatot, majd próbálja meg újra.",

        //language picker
        searchLanguage: "Keresés nyelv szerint",
    }
}
